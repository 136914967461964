import React, {useEffect, useRef, useState} from "react";

import "./TableAttributeAnalyzer.css";

import {UnorderedListOutlined} from "@ant-design/icons";
import {Button, Tooltip, Dropdown, Menu, Slider, Space, Table} from "antd";
import FileDownload from "js-file-download";
import * as _ from "lodash";

import ExamplesTableAttributeAnalyzer from "./ExamplesTableAttributeAnalyzer";
import SubmitActions from "./SubmitActions";
import api from "../../api";
import * as Auth from "../../AuthService";
import {FINAL_TABLE_PAGE_SIZE, properties} from "../../properties";

import "../../index.less";

function DropdownExamplesVariants({getExamples, getDownload}) {
	const [count, setCount] = useState(50);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const onChange = value => {
		setCount(value);
	};

	const handleMenuClick = e => {
		if (e.key !== "slider") {
			setOpen(false);
		}
	};

	const menu = (
		<Menu onClick={handleMenuClick} style={{width: 200}}>
			<Menu.Item key="sample" onClick={() => getExamples(count, true, state => setLoading(state))}>
				Random {count} examples
			</Menu.Item>
			<Menu.Item key="first" onClick={() => getExamples(count, false, state => setLoading(state))}>
				First {count} examples
			</Menu.Item>
			<Menu.Item key="download" onClick={() => getDownload(state => setLoading(state))}>
				Download all examples
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item key="slider">
				Count of examples: <Slider min={10} max={100} step={5} defaultValue={count} onChange={onChange} />
			</Menu.Item>
		</Menu>
	);

	return (
		<Dropdown overlay={menu} open={open} onOpenChange={setOpen} trigger={["click"]}>
			<Button
				type="primary"
				className="btn-primary"
				loading={loading}
				icon={<UnorderedListOutlined style={{color: "white", fontSize: 20}} />}></Button>
		</Dropdown>
	);
}

export default function TableAttributeAnalyzer({
	dataSource,
	statisticsAmount,
	attributeAnalyzerPage,
	source,
	attributes,
	handleSearch,
	searchExamples,
}) {
	const sortStateRef = useRef({});

	const [selectedRowHash, setSelectedRowHash] = useState(null);
	const [selectedHashes, setSelectedHashes] = useState([]);

	const [examplesManyLoading, setExamplesManyLoading] = useState(false)
	const [examples, setExamples] = useState([]);
	const [examplesPage, setExamplesPage] = useState(1);

	const [verifiedPercentageFilter, setVerifiedPercentageFilter] = useState(null);

	const getExamples = (action, count = 50, sample = false, setLoadingGetExamples) => {
		setLoadingGetExamples(true);
		api.get(
			`/statistics/attribute/examples?value=${action.value}&hash=${action.hash}&source=${action.source}&class=${action.class}&attribute=${action.attribute}&batch=${action.batch}&count=${count}&sample=${sample}`,
			Auth.createConfig(),
		)
			.then(json => {
				const _examples = json.data.examples.map(example => {
					const description = example.description;
					let _description = "";
					if (example.positions && example.positions.length > 0) {
						const positions = _.sortBy(example.positions, "from");
						let lastIndex = 0;
						positions.map(position => {
							_description += description.substring(lastIndex, position.from);
							_description += `<span style='background-color: ${properties.colors.yellow}';>${description.substr(position.from, position.length)}</span>`;
							lastIndex = position.from + position.length;
						});
						_description += description.substr(lastIndex);
					}
					return {
						item: example.item,
						class: example.class,
						description: _description.length > 0 ? _description : description,
						value: example.value,
						tokens: example.tokens,
						source: example.source,
					};
				});
				setSelectedRowHash([action.hash]);
				setExamples(_examples);
				setExamplesPage(1);
			})
			.catch(error => {
				console.log(error);
				alert("Sorry, something went wrong.");
			})
			.finally(() => setLoadingGetExamples(false));
	};

	const getManyExamples = () => {
		setExamplesManyLoading(true)

		const filteredData = dataSource
			.filter(item => selectedHashes.includes(item.hash))
			// .filter(item => item.frequency[0] <= 50 && item.verifiedPercentage != 100)
			.map(item => item.hash);

		const _action = {
			batch: dataSource[0].action.batch,
			class: dataSource[0].action.class,
			attribute: dataSource[0].action.attribute, 
			some_name_array: filteredData
		};

		api.post(
			"/statistics/attribute/examples_many",
			_action,
			Auth.createConfig(),
		)
			.then(json => {
				const _examples = json.data.examples.map(example => {
					const description = example.description;
					let _description = "";
					if (example.positions && example.positions.length > 0) {
						const positions = _.sortBy(example.positions, "from");
						let lastIndex = 0;
						positions.map(position => {
							_description += description.substring(lastIndex, position.from);
							_description += `<span style='background-color: ${properties.colors.yellow}';>${description.substr(position.from, position.length)}</span>`;
							lastIndex = position.from + position.length;
						});
						_description += description.substr(lastIndex);
					}
					return {
						item: example.item,
						class: example.class,
						description: _description.length > 0 ? _description : description,
						value: example.value,
						tokens: example.tokens,
						source: example.source,
					};
				});
				setSelectedRowHash(filteredData);
				setExamples(_examples);
				setExamplesPage(1);
			})
			.catch(error => {
				console.log(error);
				alert("Sorry, something went wrong.");
			})
			.finally(() => setExamplesManyLoading(false));
	}

	const downloadExamples = (action, setLoadingDownloadExamples) => {
		setLoadingDownloadExamples(true);
		const config = Auth.createConfig();
		config["responseType"] = "blob";
		api.get(
			`/statistics/attribute/examples/download?value=${action.value}&hash=${action.hash}&source=${action.source}&class=${action.class}&attribute=${action.attribute}&batch=${action.batch}`,
			config,
		)
			.then(response => {
				const header = response.headers["content-disposition"];
				const filename = /filename=(.*)/.exec(header)[1];
				FileDownload(response.data, filename);
			})
			.catch(error => {
				console.log(error);
				alert("Sorry, something went wrong.");
			})
			.finally(() => setLoadingDownloadExamples(false));
	};

	const submitValues = () => {
		// console.log(selectedHashes)
		const filteredData = dataSource
			.filter(item => selectedHashes.includes(item.hash))
			.map(item => ({hash: item.hash, value: item.value}));

		// console.log(filteredData);

		const _action = {attribute: dataSource[0].action.attribute, some_name_array: filteredData};
		api.post(`/statistics/attribute/final/change_many`, _action, Auth.createConfig())
			.then(json => {
				handleSearch(attributeAnalyzerPage, undefined, verifiedPercentageFilter || [], sortStateRef.current);
				setSelectedRowHash(null);
				setExamples([]);
			})
			.catch(error => {
				console.log(error);
				alert("Sorry, something went wrong while update many values.");
			});
	};

	const onChangePage = (pagination, filters, sorter) => {
		if (source == "finals") {
			const filter = filters.verifiedPercentage ? [filters.verifiedPercentage[0]] : null;
			const sorting = {
				column: sorter["field"],
				order: sorter["order"],
			};

			setVerifiedPercentageFilter(filter);
			sortStateRef.current = sorting;

			handleSearch(pagination.current, pagination.pageSize, filter || [], sorting);
		}
	};

	const columns = [
		{
			title: "Submit",
			dataIndex: "verifiedPercentage",
			width: 55,
			hidden: source !== "finals",
			filters: [
				{
					text: "All Done",
					value: "all done",
				},
				{
					text: "Final Stages",
					value: "final stages",
				},
				{
					text: "Early Stages",
					value: "early stages",
				},
				{
					text: "Not Started",
					value: "not started",
				},
			],
			filteredValue: verifiedPercentageFilter,
			render: verifiedPercentage => {
				const percentColors = [
					{pct: 0.0, color: {r: 0xff, g: 0x63, b: 0x47}},
					{pct: 50.0, color: {r: 0xf0, g: 0xe6, b: 0x8c}},
					{pct: 100.0, color: {r: 0x00, g: 0x80, b: 0x00}},
				];

				const getColorForPercentage = pct => {
					let lower = percentColors[0];
					let upper = percentColors[percentColors.length - 1];
					for (let i = 1; i < percentColors.length - 1; i++) {
						if (pct <= percentColors[i].pct) {
							lower = percentColors[i - 1];
							upper = percentColors[i];
							break;
						}
					}
					const range = upper.pct - lower.pct;
					const rangePct = (pct - lower.pct) / range;
					const pctLower = 1 - rangePct;
					const pctUpper = rangePct;
					const color = {
						r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
						g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
						b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
					};
					return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
				};
				return (
					<p
						style={{
							paddingBottom: 11,
							margin: 0,
							color: getColorForPercentage(verifiedPercentage),
							fontSize: 54,
							pointerEvents: "none",
							userSelect: "none",
						}}>
						●
					</p>
				);
			},
		},
		{
			title: "Value",
			dataIndex: "value",
			width: 300,
			sorter: source == "finals" ? true : (a, b) => a.value.localeCompare(b.value),
			render: value => <p style={{margin: 3, whiteSpace: "break-spaces", wordBreak: "break-word"}}>{value}</p>,
		},
		{
			title: "Token",
			dataIndex: "token",
			hidden: source !== "finals",
			width: 200,
			sorter: source == "finals" ? true : (a, b) => a.value.localeCompare(b.value),
			render: token => <p style={{margin: 3, whiteSpace: "break-spaces", wordBreak: "break-word"}}>{token}</p>,
		},
		{
			title: "Frequency",
			dataIndex: "frequency",
			width: 50,
			defaultSortOrder: "descend",
			sorter: source == "finals" ? true : (a, b) => a.value.localeCompare(b.value),
			render: (frequency, record) => (
				<span
					title={
						record.sources
							? record.sources
									.map(
										obj =>
											`${obj.source === "<empty>" ? "N/A" : obj.source}: ${obj.count} (${obj.percentage}%)`,
									)
									.join("\n")
							: ""
					}>
					{frequency[0]} ({frequency[1] > 1 ? frequency[1] : `<1`}%)
				</span>
			),
		},
		{
			title: "Actions",
			dataIndex: "action",
			width: 50,
			render: (action, record) => (
				<Space direction="horizontal">
					{action.source === "finals" && (
						<SubmitActions
							emptyFlag={record.value === "<empty>"}
							attributes={attributes}
							action={action}
							handleSearch={() => {
								handleSearch(
									attributeAnalyzerPage,
									undefined,
									verifiedPercentageFilter || [],
									sortStateRef.current,
								);
								setSelectedRowHash(null);
								setExamples([]);
							}}
						/>
					)}
					<DropdownExamplesVariants
						getExamples={(count, sample, setLoadingGetExamples) =>
							getExamples(action, count, sample, setLoadingGetExamples)
						}
						getDownload={setLoadingDownloadExamples => downloadExamples(action, setLoadingDownloadExamples)}
					/>
				</Space>
			),
		},
	].filter(item => !item.hidden);

	useEffect(
		() => () => {
			setSelectedHashes([]);
		},
		[dataSource],
	);

	return (
		<Space direction="vertical" style={{width: "100%"}}>
			<Table
				rowKey="hash"
				showSorterTooltip={false}
				tableLayout="fixed"
				className="analysis-table primary-table"
				rowClassName={record => (selectedRowHash?.some(hash => hash == record.hash) ? "highlight-row" : "")}
				columns={columns}
				dataSource={dataSource}
				rowSelection={{
					type: "checkbox",
					selectedRowKeys: selectedHashes,
					onChange: setSelectedHashes,
					getCheckboxProps: record => ({
						disabled: record.value === "<empty>" || record.verifiedPercentage === 100,
					}),
				}}
				onChange={onChangePage}
				showSizeChanger={false}
				pagination={{
					pageSize: FINAL_TABLE_PAGE_SIZE,
					...(source === "finals" && {
						current: attributeAnalyzerPage,
						total: statisticsAmount,
					}),
					showSizeChanger: false,
					showQuickJumper: true,
					showTotal: () => (
						<>
							<Button
								type="primary"
								className="btn-primary"
								onClick={submitValues}
								disabled={!selectedHashes.length}>
								Approve
							</Button>
							<Button 
								type="primary"
								className="btn-primary"
								onClick={getManyExamples}
								disabled={examplesManyLoading || !selectedHashes.length}>
								All examples	
							</Button>
						</>

					),
				}}
			/>
			{examples.length > 0 && (
				<ExamplesTableAttributeAnalyzer
					dataSource={examples}
					page={examplesPage}
					setPage={page => setExamplesPage(page)}
					searchExamples={searchExamples}
				/>
			)}
		</Space>
	);
}
