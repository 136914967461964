import React, {useState} from "react";

import {Col, Row} from "antd";

import {ClassLibraryComponent} from "./Class Library Component";
import {SourceMatrix} from "./SourceMatrix";

export function MaturitySourceRoot({}) {
	const [selectedClass, setSelectedClass] = useState("");

	return (
		<Row>
			<Col span={8} style={{padding: 10}}>
				<ClassLibraryComponent selectedClass={selectedClass} setSelectedClass={setSelectedClass} />
			</Col>
			<Col span={16} style={{padding: 10}}>
				<SourceMatrix selectedClass={selectedClass} />
			</Col>
		</Row>
	);
}
