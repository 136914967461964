import * as React from "react";

import "./DataAnalyzer.css";

import {Dropdown, Menu as DropdownMenu, Select, Spin} from "antd";
import FileDownload from "js-file-download";
import * as _ from "lodash";
import {Button, Card} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Table from "react-bootstrap/Table";
import Pagination from "react-js-pagination";

import api from "../api";
import * as Auth from "../AuthService";
import {properties} from "../properties";

import "../index.less";

class ExampleRow extends React.Component {
	render() {
		const value = this.props.example.description;
		const from = value.indexOf(this.props.example.used_token);
		const length = this.props.example.used_token.length;

		return (
			<tr>
				<td>{this.props.example.item}</td>
				<td>{this.props.example.class_name}</td>
				<td>
					{value.substr(0, from)}
					<span className="match">{value.substr(from, length)}</span>
					{value.substr(from + length)}
				</td>
				<td>{this.props.example.used_token}</td>
			</tr>
		);
	}
}

class ExampleTable extends React.Component {
	state = {};

	render() {
		const rows = this.props.examples.map((v, i) => <ExampleRow key={i + 1} index={i + 1} example={v} />);

		return rows.length > 0 ? (
			<div>
				<p style={{marginTop: 10}}>
					<b>Examples for token - {this.props.token}</b>
				</p>
				<Card border="secondary">
					<Card.Body className="test">
						<div>
							<Table bordered hover className="Stats">
								<thead>
									<tr>
										<th>ID</th>
										<th>Class</th>
										<th>Description</th>
										<th>Token</th>
									</tr>
								</thead>
								<tbody>{rows}</tbody>
							</Table>
						</div>
					</Card.Body>
				</Card>
			</div>
		) : null;
	}
}

class ResultRow extends React.Component {
	markAsGarb() {
		const new_rule = {
			name: "Ignore (" + this.props.item.token + ")",
			type: "garbage",
			template: this.props.item.token,
			markers: [],
			token: this.props.item.token,
			for: {
				_class: this.props.class,
				attribute: "IGNORE LIST",
			},
		};
		api.post(`/save/rule`, new_rule, Auth.createConfig())
			.then(json => {
				alert("Rule added to Ignore List!");
				this.props.handleSave();
			})
			.catch(error => console.log(error));
	}

	render() {
		const token = _.replace(
			_.replace(
				this.props.item.token,
				/float/g,
				`<span style="background-color: ${properties.shades.softSkyBlue}">float</span>`,
			),
			/number/g,
			`<span style="background-color: ${properties.colors.yellow}">number</span>`,
		);
		const freak = (this.props.item.frequency / this.props.size).valueOf().toPrecision(2) * 100;
		const rules = this.props.item.rules.map(rule => (
			<span className="rule" onClick={() => this.props.openRule(rule)}>
				{" "}
				{rule.name}
			</span>
		));

		return (
			<tr>
				<td>
					<span dangerouslySetInnerHTML={{__html: token}} />
				</td>
				<td>
					{this.props.item.frequency} ({Math.round(freak)} %)
				</td>
				<td>{rules}</td>
				<td>
					<span className="link" onClick={e => this.props.showExamples(e, this.props.item.token)}>
						Examples
					</span>
				</td>
				<td>
					<span
						className="link"
						onClick={e => {
							e.preventDefault();
							this.props.createRule(this.props.item.token, false);
						}}>
						Add rule
					</span>
				</td>
				<td>
					<span
						className="link"
						onClick={e => {
							e.preventDefault();
							this.markAsGarb();
						}}>
						Add to ignore
					</span>
				</td>
			</tr>
		);
	}
}

export class ResultTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hideExisted: true,
			hideGarbage: false,
			examples: [],
			attributes: [],
			serviceAttributes: [],
			attr: [],
			status: "Hide",
			results: [],
			addRule: false,
		};
	}

	getAttributes() {
		if (this.props.class) {
			api.get(`/rules/get?class=${this.props.class}`, Auth.createConfig())
				.then(json => this.setState({attributes: json.data}))
				.catch(error => console.log(error));
		}
	}

	componentDidMount() {
		this.getAttributes();
	}

	getArray(array) {
		const names = [];
		for (let i = 0; i < array.length; i++) {
			names.push(array[i].name);
		}
		return names.join(", ");
	}

	getListOfRules = indexes => {
		const rules = [];
		if (typeof indexes[0] === "object") {
			return indexes;
		}
		for (const ind of indexes) {
			if (typeof ind === "number") {
				rules.push(this.state.attributes[ind]);
			}
		}
		return rules;
	};

	getAnalyze() {
		const info = {
			_class: this.props.class,
			batch: this.props.batch,
			exclude: this.state.status !== "Hide",
			rules: this.getListOfRules(this.state.attr),
		};
		api.post(`/data-analyzer`, info, Auth.createConfig())
			.then(json => this.setState({results: json.data}))
			.catch(error => console.log(error));
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.class !== this.props.class) {
			this.getAttributes();
			this.setState({examples: [], results: [], attribute: [], status: "Hide", attr: []});
		}
		if (prevProps.shouldUpdate !== this.props.shouldUpdate) {
			this.getAnalyze();
			this.getAttributes();
		}
	}

	showExamples(event, token) {
		api.get(
			`/token/examples?token=${token}&class=${this.props.class}&batch=${this.props.batch}`,
			Auth.createConfig(),
		)
			.then(json => this.setState({examples: json.data, token: token}))
			.catch(error => console.log(error));
	}

	createSelectItems() {
		const items = [
			{label: "Select all", value: "all"},
			{label: "Deselect all", value: "none"},
		];
		if (this.state.attributes) {
			for (let i = 0; i < this.state.attributes.length; i++) {
				items.push({
					key: i,
					value: i,
					label: `[ ${this.state.attributes[i].attribute} ] ${this.state.attributes[i].name}`,
				});
			}
		}
		return items;
	}

	handleSelection(values) {
		this.setState({attr: this.checkOperators(values)});
	}
	checkSelection(selectedOperators) {
		if (selectedOperators.indexOf("all") > -1) {
			return this.state.attributes.map((v, i) => i);
		} else if (selectedOperators.indexOf("none") > -1) {
			return [];
		}
		return selectedOperators;
	}

	checkOperators(values) {
		//     let array = [];
		//     if(event.target.name === 'operators'){
		//         array = this.checkSelection(event.target.value)
		//     } else{
		//         array = this.state.attr;
		//     }
		return this.checkSelection(values);
	}

	render() {
		const rows = this.state.results.stats
			? this.state.results.stats.map((v, i) => (
					<ResultRow
						key={i + 1}
						size={this.state.results.size}
						index={i + 1}
						item={v}
						handleSave={() => this.props.handleSave()}
						openRule={rule => this.props.openRule(rule)}
						createRule={(token, isGarb) => this.props.createRule(token, isGarb)}
						showExamples={(e, token) => this.showExamples(e, token)}
						class={this.props.class}
					/>
				))
			: [];

		return (
			<div>
				<div style={{marginTop: 10, marginBottom: 10}}>
					<div style={{marginTop: 5}}>
						Analysis for class: <b>{this.props.class}</b> (batch <b>{this.props.batch}</b>)
					</div>
					<div>
						Total number of items of the class in the batch:{" "}
						<b>{this.state.results.size ? this.state.results.size.toLocaleString() : ""}</b>
					</div>
					<div style={{float: "right", marginTop: -50, display: "inline"}}>
						<div>
							<Select
								name="status"
								style={{width: 100, marginRight: 10, marginTop: 10}}
								value={this.state.status}
								options={[
									{value: "Hide", label: "Hide"},
									{value: "Show", label: "Show"},
								]}
								onChange={value => this.setState({status: value})}
								renderValue={selected => selected}></Select>
							<label className="lbl" htmlFor="operatorCB">
								Choose rule:
							</label>
							<Select
								multiple
								name="operators"
								style={{width: 450, maxWidth: 450, maxHeight: 40}}
								maxTagCount={1}
								value={this.state.attr}
								mode={"multiple"}
								onChange={values => this.handleSelection(values)}
								options={this.createSelectItems()}></Select>
							<Button
								className="primary-button"
								style={{marginTop: 5, float: "right"}}
								onClick={e => {
									e.preventDefault();
									this.getAnalyze();
								}}>
								Search
							</Button>
						</div>
						<div></div>
					</div>
				</div>
				{this.state.results.stats && (
					<Card border="secondary" style={{marginTop: 40}}>
						<Card.Body className="test">
							<div className="test">
								<Table bordered hover className="Stats">
									<thead>
										<tr>
											<th>Token</th>
											<th>Frequency</th>
											<th>Existing rules</th>
											<th colSpan={3}>Action</th>
										</tr>
									</thead>
									<tbody>{rows}</tbody>
								</Table>
							</div>
						</Card.Body>
					</Card>
				)}
				{this.props.class && <ExampleTable examples={this.state.examples} token={this.state.token} />}
			</div>
		);
	}
}

export function EntityLinksDropdown({material, tagNumber, type, children, batch}) {
	const items = (
		<DropdownMenu>
			<DropdownMenu.Item>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={`${properties.analyticsLink}/projects/6295c7173ae8947d89af2c73/min-max-material-analysis?material=${material}`}>
					Workbench
				</a>
			</DropdownMenu.Item>
			<DropdownMenu.Item>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={`${properties.ihLink}/data?operator=Client&material=${material}`}>
					Inventory
				</a>
			</DropdownMenu.Item>
			<DropdownMenu.Item>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={`${properties.khLink}/user_view/search?q=${tagNumber || material}`}>
					Knowledge
				</a>
			</DropdownMenu.Item>
			<DropdownMenu.Item>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={`${properties.dmLink}/dm?search=${material}&batch=${batch}`}>
					Engineering
				</a>
			</DropdownMenu.Item>
			<DropdownMenu.Item>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={`${properties.assetHubLink}/entity_links?type=${type}&item=${material}`}>
					Equipment
				</a>
			</DropdownMenu.Item>
		</DropdownMenu>
	);

	return <Dropdown overlay={items}>{children}</Dropdown>;
}

class SourceRow extends React.Component {
	render() {
		return (
			<tr style={{backgroundColor: this.props.markups ? (this.props.markups > 0 ? "#e9f7b5" : "") : ""}}>
				<td>
					{this.props.index}{" "}
					{this.props.final ? this.props.final > 0 ? <Image src="/images/star.png" /> : "" : ""}
				</td>
				<td>{this.props.item.class_name}</td>
				<td>{this.props.item.item}</td>
				{/*<td>{this.props.item.source}</td>*/}
				<td>{this.props.item.description}</td>
				<td style={{color: "blue", fontSize: 14, textDecorationLine: "underline", whiteSpace: "nowrap"}}>
					<p onClick={() => this.props.handleAnnotation(this.props.item)}>Annotate</p>
					<p onClick={() => this.props.openQA(this.props.item)}>Open in QA</p>
					<p>
						<EntityLinksDropdown
							material={this.props.item.item}
							batch={this.props.batch}
							type={"material"}
							children={<div>Links</div>}
						/>
					</p>
					{/*<a target="_blank" style={{'color':"blue"}} rel="noopener noreferrer" href={this.props.item.url}>Open in IH</a>*/}
				</td>
				<td>
					<Image onClick={() => this.props.updateClass(this.props.item)} src="/images/edit-user-blue.png" />
				</td>
			</tr>
		);
	}
}

export class SourceTable extends React.Component {
	state = {
		loading: false,
	};

	exportFullExtraction = () => {
		this.setState({loading: true});
		const config = Auth.createConfig();
		config["responseType"] = "blob";
		api.get(`/batch/full/export?batch=${this.props.batch}&class=${this.props.class}`, config)
			.then(response => {
				const header = response.headers["content-disposition"];
				const filename = /filename=(.*)/.exec(header)[1];
				FileDownload(response.data, filename, filename);
			})
			.catch(error => {
				console.log(error);
				alert("Sorry, something went wrong.");
			})
			.finally(() => {
				this.setState({loading: false});
			});
	};

	render() {
		const exportButton = (
			<Button
				className="single-btn-export primary-button"
				size={"sm"}
				onClick={this.exportFullExtraction}
				disabled={this.state.loading}>
				{this.state.loading ? "Getting Extractions... " : "Attribute Extraction"}{" "}
				{this.state.loading && <Spin />}
			</Button>
		);

		if (this.props.source.length > 0) {
			const rows = this.props.source.map((v, i) => (
				<SourceRow
					key={i + 1}
					index={i + 1 + (this.props.currentKey - 1) * properties.previewRowsPerPage}
					item={v}
					batch={this.props.batch}
					final={this.props.final[i]}
					updateClass={item => this.props.updateClass(item)}
					markups={this.props.markups[i]}
					openQA={item => this.props.openQA(item)}
					handleAnnotation={item => this.props.handleAnnotation(item)}
				/>
			));

			return (
				<div>
					<div style={{marginTop: 5}}>
						{this.props.class ? (
							<div>
								Results for class: <b>{this.props.class}</b> (batch <b>{this.props.batch}</b>)
								{exportButton}
							</div>
						) : (
							<div>
								Results for batch: <b>{this.props.batch}</b> {exportButton}{" "}
							</div>
						)}
					</div>
					<div>
						Total number of items in the batch: <b>{this.props.batchSize.toLocaleString()}</b> (Verified:{" "}
						<b>{this.props.approved.toLocaleString()}</b>)
					</div>
					<Table style={{marginTop: 5}} bordered hover className="Results">
						<thead>
							<tr>
								<th>#</th>
								<th>Class</th>
								<th>Item</th>
								{/*<th>Source</th>*/}
								<th>Description</th>
								<th />
								<th>Edit class</th>
							</tr>
						</thead>
						<tbody>{rows}</tbody>
					</Table>
					{this.props.size > properties.previewRowsPerPage && (
						<div>
							<Pagination
								itemClass="page-item"
								linkClass="page-link"
								activePage={this.props.currentKey}
								itemsCountPerPage={properties.previewRowsPerPage}
								totalItemsCount={this.props.size}
								pageRangeDisplayed={25}
								onChange={page => this.props.handlePagination(page - 1)}
							/>
						</div>
					)}
				</div>
			);
		} else {
			return <h3>No source examples for this class</h3>;
		}
	}
}

export default SourceTable;
