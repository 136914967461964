import React, {useContext, useEffect, useRef, useState} from "react";

import {Button, Col, Form, Input, Row} from "antd";

export const EditableContext = React.createContext(null);
export const EditableRow = ({index, ...props}) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};
export const EditableCell = ({title, editable, children, dataIndex, record, handleSave, ...restProps}) => {
	const [editing, setEditing] = useState(false);
	const inputRef = useRef(null);
	const form = useContext(EditableContext);
	useEffect(() => {
		if (editing) {
			const data = restProps["data"].filter(x => x["key"] === record["name"])[0];
			form.setFieldsValue({
				[dataIndex]: data["value"],
			});
			inputRef.current.focus();
		}
	}, [editing]);
	const toggleEdit = () => {
		setEditing(!editing);
		form.setFieldsValue({
			[dataIndex]: record[dataIndex],
		});
	};

	const cancel = () => {
		setEditing(!editing);
	};

	const save = async () => {
		try {
			const values = await form.validateFields();
			if (typeof restProps["data"].filter(x => x["key"] === record["name"])[0]["value"] === "object") {
				const splitted = values["value"].split(",");
				const proccessedValues = [];
				for (const _split of splitted) {
					const val = _split.trim();
					if (val) {
						proccessedValues.push(val);
					}
				}

				values["value"] = proccessedValues;
			}
			toggleEdit();
			handleSave({
				...record,
				...values,
			});
		} catch (errInfo) {
			console.log("Save failed:", errInfo);
		}
	};
	let childNode = children;
	if (editable) {
		childNode = editing ? (
			<Row>
				<Col span={16}>
					<Form.Item
						style={{
							margin: 0,
						}}
						name={dataIndex}
						rules={[
							{
								required: true,
								message: `${title} is required.`,
							},
						]}>
						<Input ref={inputRef} onPressEnter={save} />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Button style={{float: "right", marginLeft: 10}} onClick={cancel}>
						Cancel
					</Button>
					<Button style={{float: "right"}} onClick={save}>
						Save
					</Button>
				</Col>
			</Row>
		) : (
			<div
				className="editable-cell-value-wrap"
				style={{
					paddingRight: 24,
				}}
				onClick={toggleEdit}>
				{children}
			</div>
		);
	}

	return <td {...restProps}>{childNode}</td>;
};
