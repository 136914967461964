import React, { useEffect, useRef } from 'react';

import "./Resizer.css";

import { useSpring, animated } from 'react-spring';
import { useDrag } from '@use-gesture/react';

const Resizer = ({ parrentRef, children, callBack, isHidden, sidebarWidth, updateTrigger, handleBorder }) => {

  const childrenRef = useRef(null);
  const [{ x }, api] = useSpring(() => ({ x: 344 }));

 const apiStarted = () => {
  api.start({ 
    x: isHidden ? 20 :  sidebarWidth - 6,
    config: {
      duration:  300
    },
    onRest: () =>  {
      handleBorder(isHidden ? 0 : 2)
    } 
  });
}

  useEffect(() => {
      apiStarted();
  }, [isHidden, api, updateTrigger ]);

  const bind = useDrag(({ down, movement: [mx], memo = x.get(), event }) => {

    const isClickOnChildren = childrenRef.current && childrenRef.current.contains(event.target);

    if (isClickOnChildren) {
      return memo;
    }

    const newX = memo + mx;
    const maxX = parrentRef.current.offsetWidth * 0.7;

    const clampedX = Math.max(0, Math.min(newX, maxX));

    api.start({ x: clampedX , immediate: down });

    if (!down) {
      callBack(clampedX + 6);
    }
    
    return memo;
  }, {
    axis: 'x',
    bounds: parrentRef,
  });

  return (
    <animated.div
      className="resizer"
      {...bind()}
      style={{ x }}>
      {React.cloneElement(children, { ref: childrenRef })}
    </animated.div>
  );
};

export default Resizer;