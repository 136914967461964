import React, {useEffect, useState} from "react";

import {CheckOutlined, ClearOutlined, EditOutlined} from "@ant-design/icons";
import {Button, Dropdown, Input, Menu, Space, Select} from "antd";

import api from "../../api";
import * as Auth from "../../AuthService";

import "../../index.less";

const EditField = ({clearAction, submitAction, value, attributes, attributeToVerified, loading, emptyFlag}) => {

	const [open, setOpen] = useState(false);
	const [currentValue, setCurrentValue] = useState("");
	const [attributeToMove, setAttributeToMove] = useState(attributeToVerified);

	useEffect(() => {
		setCurrentValue(value);
	}, [value]);

	const handleMenuClick = e => {
		if (e.key !== "input") {
			setOpen(false);
		}
	};

	const handleSubmit = () => {
		submitAction(currentValue, attributeToMove);
		setOpen(false);
	};

	useEffect(() => {
		if (open) {
			setAttributeToMove(attributeToVerified)
		}
	}, [open]);

	const menu = (
		<Menu onClick={handleMenuClick}>
			<Menu.Item key="input">
				<Space style={{display: "flex"}}>
					<Input
						onKeyDown={e => (e.key === "Enter" ? handleSubmit() : null)}
						value={currentValue}
						onChange={e => setCurrentValue(e.target.value)}
					/>
					<Select
						placeholder="Select Attribute"
						style={{width: 120}}
						value={attributeToMove}
						options={attributes.map(attribute => ({
							value: attribute.attributeName,
							label: attribute.attributeName,
						}))}
						onChange={setAttributeToMove}
					/>
				</Space>
			</Menu.Item>
			<Menu.Item key="submit_change">
				<Space style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
					<Button
						className="btn-primary"
						type="primary"
						onClick={handleSubmit}
						style={{fontSize: 20, width: 64}}
						icon={<CheckOutlined />}
					/>
					<Button
						className="btn-primary"
						type="primary"
						onClick={() => {
							clearAction();
							setOpen(false);
						}}
						style={{fontSize: 20, width: 64}}
						icon={<ClearOutlined />}
					/>
				</Space>
			</Menu.Item>
		</Menu>
	);
	return (
		<Dropdown overlayStyle={{zIndex: 0}} disabled={emptyFlag} overlay={menu} open={open} onOpenChange={setOpen} trigger={["click"]}>
			<Button
				type="primary"
				className="btn-primary"
				loading={loading}
				icon={<EditOutlined style={{color: "white", fontSize: 20}} />}></Button>
		</Dropdown>
	);
};

export default function SubmitActions({emptyFlag, attributes, action, handleSearch}) {
	const [loading, setLoading] = useState(false);

	const submitValue = (value, targetAttribute) => {
		setLoading(true);
		const _action = {...action, target_attribute: targetAttribute, old_value: action.value, value: value};
		api.post(`/statistics/attribute/final/change`, _action, Auth.createConfig())
			.then(json => {
				handleSearch();
			})
			.catch(error => console.log(error))
			.finally(() => setLoading(false));
	};

	const clearAction = () => {
		setLoading(true);
		api.post(`/statistics/attribute/final/clear`, action, Auth.createConfig())
			.then(json => {
				handleSearch();
			})
			.catch(error => console.log(error))
			.finally(() => setLoading(false));
	};

	return (
		<>
			<EditField
				submitAction={(value, attr) => submitValue(value, attr)}
				value={action.value}
				attributes={attributes}
				attributeToVerified={action.attribute}
				loading={loading}
				clearAction={() => clearAction()}
				emptyFlag={emptyFlag}
			/>
		</>
	);
}
