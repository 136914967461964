import uiConfig from "./config/ui-config-chevron.json";

export const properties = {
	apiUrl: process.env.REACT_APP_HOST,
	previewRowsPerPage: 50,
	version: process.env.REACT_APP_DM_VERSION,
	colors: {
		oceanBlue: "#004987",
		lightBlue: "#A2C7E2",
		yellow: "#ffda00",
		darkBlue: "#00205C",
		green: "#00783F",
		black: "#231f20",
		brightBlue: "#007fc5",
		darkRed: "#4c212a",
	},
	shades: {
		lightPink: "#fac8d6",
		softRose: "#ffdbdd",
		lightCoral: "#fac8c8",
		pastelPink: "#ffdbeb",
		lightMagenta: "#fbe4f4",
		softLavender: "#fddbff",
		paleLilac: "#f3defa",
		lightPurple: "#f3dbff",
		pastelViolet: "#eadbff",
		lightLilac: "#f1e1f6",
		palePeriwinkle: "#dbe4ff",
		softSkyBlue: "#dbf1ff",
		lightAqua: "#dbfff8",
		pastelMint: "#c8f9e0",
		mintGreen: "#dbffdb",
		paleMint: "#d9f6ff",
		lightGreen: "#f3ffdb",
		paleLime: "#feffdb",
		softButter: "#f9f9e6",
		palePeach: "#ffeedb",
		lightSalmon: "#ffeadb",
		pastelCoral: "#ffe6d9",
		softRosePink: "#ffdcdb",
		lightRed: "#ffdbdb",
		paleSkyBlue: "#dbe4ff",
		lightMint: "#dbf1ff",
		lightAquaTwo: "#dbfff8",
		softMint: "#dbffea",
		mintGreenTwo: "#dbffdb",
		lightGreenTwo: "#ebffdb",
		paleLimeTwo: "#f3ffdb",
	},
	ihLink: process.env.REACT_APP_IH_LINK,
	dmLink: process.env.REACT_APP_DM_LINK,
	khLink: process.env.REACT_APP_KH_LINK,
	clLink: process.env.REACT_APP_CL_LINK,
	analyticsLink: process.env.REACT_APP_WB_LINK,
	assetHubLink: process.env.REACT_APP_EH_LINK,
	clPublicLink: process.env.REACT_APP_CL_LINK_PUBLIC,
};

export const SHOW_DM_IN_MENU =
	process.env.REACT_APP_SHOW_DM_IN_MENU === undefined ? true : process.env.REACT_APP_SHOW_DM_IN_MENU === "true";
export const SHOW_SETTINGS =
	process.env.REACT_APP_SHOW_SETTINGS === undefined ? true : process.env.REACT_APP_SHOW_SETTINGS === "true";
export const SHOW_REGISTER =
	process.env.REACT_APP_SHOW_REGISTER === undefined ? true : process.env.REACT_APP_SHOW_REGISTER === "true";
export const SHOW_REPORTS =
	process.env.REACT_APP_SHOW_REPORTS === undefined ? true : process.env.REACT_APP_SHOW_REPORTS === "true";
export const SHOW_SOURCE_MATRIX =
	process.env.REACT_APP_SHOW_SOURCE_MATRIX === undefined ? true : process.env.REACT_APP_SHOW_SOURCE_MATRIX === "true";

export const FINAL_TABLE_PAGE_SIZE = process.env.REACT_APP_FINAL_TABLE_PAGE_SIZE;

export const KEYCLOAK_AUTH_SERVER_URL = process.env.REACT_APP_KEYCLOAK_AUTH_SERVER_URL;

export const SHOW_FIRST_IN_REGISTERS = process.env.REACT_APP_SHOW_FIRST_IN_REGISTERS;
export const OWNER = process.env.REACT_APP_OWNER;
export const BATCH_WITH_ALL_MATERIALS = process.env.REACT_APP_BATCH_WITH_ALL_MATERIALS;
export const REACT_APP_UI_THEME = process.env.REACT_APP_UI_THEME;

export const JIRA_DATA_KEY = process.env.REACT_APP_JIRA_DATA_KEY;

uiConfig.header.aboutText = process.env.REACT_APP_HEADER_DESCRIPTION;
export const UI_CONFIG = uiConfig;
