import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, LabelList } from 'recharts';

const VerticalBarChart = ({ data }) => {
    const order = ['totalAmount', 'verified', 'verifiedIgnoringBlanks', 'partialyVerified'];

    // Преобразуем данные в chartData с учётом нужного порядка
    const chartData = order.map((key) => ({
      name: key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()), // Преобразуем ключ в строку с пробелами и заглавной буквой
      value: data[key],
    }));  

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Bar dataKey="value" fill="#8884d8">
          <LabelList dataKey="value" position="top" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default VerticalBarChart;
