// import React, { useEffect, useRef, useState } from "react";

// import { Button, Card, Space, Tag } from "antd";

// import "./AdditionalStatistics.css";

// import * as Auth from "../AuthService";
// import api from "../NewApi";
// import VerticalBarChart from "./VerticalBarChart";

// export default function AdditionalStatistics({ batch, clsName }) {


//     return (
//         <Card
//             title={
//                 clsName ? (
//                     <>Additional statistics for class: <b>{clsName}</b> (batch: <b>{batch}</b>)</>
//                 ) : (
//                     <>Additional statistics for batch: <b>{batch}</b></>
//                 )
//             }
//             className="primary-card analysis-card">
//             <div className="card-container">
//                 <div className="stat-card">Text for Card 1</div>
//                 <div className="stat-card">Text for Card 2</div>
//                 <div className="stat-card">Text for Card 3</div>
//                 <div className="stat-card">Text for Card 4</div>
//             </div>
//             <VerticalBarChart/>
//         </Card>
//     );
// }


import React, { useEffect, useState } from "react";
import { Button, Card, Space } from "antd";
import VerticalBarChart from "./VerticalBarChart";
import "./AdditionalStatistics.css";
import * as Auth from "../AuthService";
import api from "../NewApi";

const data = {
    forBatch: {
        verified: 45,
        verifiedIgnoringBlanks: 98,
        partialyVerified: 34,
        extracted: 123,
    },
    forClass: {
        verified: 425,
        verifiedIgnoringBlanks: 938,
        partialyVerified: 374,
        extracted: 1231,
    },
};

export default function AdditionalStatistics({ batch, clsName }) {

    const [isLoading, setIsLoading] = useState(false)

    const [allData, setAllData] = useState(null)
    const [selectedData, setSelectedData] = useState({});
    const [activeButton, setActiveButton] = useState("set1");

    const handleButtonClick = (dataKey, button) => {
        setSelectedData(allData[dataKey]);
        setActiveButton(button);
    };

    const getStatistics = async () => {
        setIsLoading(true)
        try {
            const re = await api.get(`/report/statistics/additional?class_name=${clsName}&batch_name=${batch}`, Auth.createConfig());
            const data = re?.data
            
            if (data) {
                console.log(data)
                setAllData(data);
                setSelectedData(data["forBatch"])
            }
        } catch (error) {
            console.error("Error while calling getStatistics:", error.message);
        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (batch) {
            setAllData(null)
            setSelectedData({})
            setActiveButton("set1")
        }
    }, [batch, clsName]);

    return (
        <Card
            title={(
                <>
                    {
                        clsName ? (
                            <>Additional statistics for class:<span>&nbsp;</span><b>{clsName}</b><span>&nbsp;</span>(batch:<span>&nbsp;</span><b>{batch}</b>)</>
                        ) : (
                            <>Additional statistics for batch:<span>&nbsp;</span><b>{batch}</b></>
                        )
                    }
                    <Button
                        type="primary"
                        className="btn-primary"
                        onClick={getStatistics}
                        disabled={isLoading}>
                        Get statistics
                    </Button>
                </>
            )}
            className="primary-card analysis-card"
        >
            {allData &&
                <div className="stats-container">
                    <div className="button-group">
                        <button
                            onClick={() => handleButtonClick("forBatch", "set1")}
                            className={`custom-button ${activeButton === "set1" ? "active" : ""}`}
                        >
                            For batch
                        </button>
                        <button
                            onClick={() => handleButtonClick("forClass", "set2")}
                            className={`custom-button ${activeButton === "set2" ? "active" : ""}`}
                            disabled={!clsName}
                        >
                            For class
                        </button>
                    </div>
                    <VerticalBarChart data={selectedData} />
                </div>}
        </Card>
    );
}