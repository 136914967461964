import React, {useCallback, useEffect, useState} from "react";

import {Input, Select, Spin, Table} from "antd";

import {DataCard} from "./components/DataCard";
import api from "../api";
import {properties} from "../properties";
import {SourceTag} from "../Register/AttributeViewer";
import {AsyncDataSource} from "../Register/Register";

export function ManualPagedTable({
	asyncDataSource,
	filteredInfo,
	setFilteredInfo,
	additionalFilter = {},
	updateTime,
	showTags = false,
	defaultLimit = 10,
	setCount,
	tagsValue,
	setTagsValue,
	saveFilters,
	isModalVisible,
	saveQuery,
	spinDelay = 100,
	showSearch = true,
	showSizeChanger = true,
	mode = "",
	...rest
}) {
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(defaultLimit);

	useEffect(() => {
		setPage(1);
	}, [limit, asyncDataSource]);

	const onPageChange = useCallback((page, pageSize) => {
		setPage(page);
		setLimit(pageSize);
	}, []);

	useEffect(() => {
		filteredInfo && setFilteredInfo({});
	}, [mode]);

	const onChange = (pagination, _filters, sorter) => {
		if (_filters !== filteredInfo) {
			setFilteredInfo({...filteredInfo, ..._filters});
			saveFilters && saveFilters({...filteredInfo, ..._filters});
		}
	};

	const handleTagSelect = tags => {
		setFilteredInfo({...filteredInfo, source: tags});
		// saveFilters && saveFilters({...filteredInfo, ..._filters})
	};

	return (
		<>
			<TablePage
				asyncDataSource={asyncDataSource}
				updateTime={updateTime}
				page={page}
				setCount={setCount}
				isModalVisible={isModalVisible}
				saveQuery={saveQuery}
				showTags={showTags}
				onChange={onChange}
				tagsValue={tagsValue}
				setTagsValue={setTagsValue}
				filters={filteredInfo}
				handleTagSelect={handleTagSelect}
				additionalFilter={additionalFilter}
				limit={limit}
				mode={mode}
				showSearch={showSearch}
				onPageChange={onPageChange}
				spinDelay={spinDelay}
				showSizeChanger={showSizeChanger}
				{...rest}
			/>
		</>
	);
}

export function TablePage({
	asyncDataSource,
	page,
	isModalVisible,
	limit,
	filters,
	showTags,
	additionalFilter = {},
	onChange,
	handleTagSelect,
	onPageChange,
	saveQuery,
	tagsValue,
	setTagsValue,
	setCount,
	showSizeChanger = true,
	mode = "",
	spinDelay = 100,
	showSearch = true,
	updateTime,
	...rest
}) {
	const [curDataSource, setCurDataSource] = useState(new AsyncDataSource());
	const [inputValue, setInputValue] = useState("");
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [maxPage, setMaxPage] = useState(0);
	const [query, setQuery] = useState("");
	const [spinning, setSpinning] = useState(false);
	const [arrayMode, setArrayMode] = useState("and");

	const getCount = () => {
		api.post(`/register/${mode}/count?q=${query}`, {filters: filters}).then(response => {
			setCount && setCount(response.data);
		});
	};

	useEffect(() => {
		setCurDataSource(asyncDataSource);
		setMaxPage(0);
	}, [asyncDataSource]);

	useEffect(() => {
		setMaxPage(0);
	}, [limit]);

	useEffect(() => {
		let cancelled = false;
		setSpinning(true);

		curDataSource
			.values(page, limit, query, filters)
			.then(data => {
				if (!cancelled) {
					setData(data);

					setMaxPage(prev => {
						const newMax = Math.max(prev, page);

						if (page === newMax) {
							setTotal((newMax - 1) * limit + data.length + 1);
						}

						return newMax;
					});
				}
			})
			.finally(_ => {
				if (!cancelled) {
					setSpinning(false);
				}
				getCount();
			});

		return () => {
			cancelled = true;
		};
	}, [curDataSource, page, filters, limit, query, updateTime, isModalVisible]);

	const onSearch = searchText => {
		setQuery(searchText);
		saveQuery(searchText);
		setMaxPage(0);
		onPageChange(1, limit);
	};

	const handleLocalTagSelect = tags => {
		setTagsValue(tags);
		handleTagSelect(tags);
	};

	return (
		<Spin spinning={spinning} delay={spinDelay}>
			{showSearch && (
				<Input.Search
					enterButton
					value={inputValue}
					onChange={event => setInputValue(event.target.value)}
					onSearch={onSearch}
					style={{marginBottom: "16px"}}
				/>
			)}

			{showTags && (
				<>
					<span style={{color: `${properties.colors.brightBlue}`}}>Sources:</span>
					<Select
						mode="tags"
						style={{
							marginLeft: 10,
							marginBottom: 10,
							width: "50%",
						}}
						value={tagsValue}
						showSearch
						tagRender={props => <SourceTag {...props} source={props.value} />}
						placeholder="Select sources"
						onChange={handleLocalTagSelect}
						enterButton
						options={additionalFilter["source"]}
					/>
				</>
			)}
			<Table
				{...rest}
				dataSource={data.slice(0, limit)}
				onChange={onChange}
				pagination={{
					position: "bottomCenter",
					showSizeChanger: showSizeChanger,
					current: page,
					total: total,
					pageSize: limit,
					onChange: onPageChange,
				}}
			/>
		</Spin>
	);
}

class ClassesDataSource extends AsyncDataSource {
	async values(page, limit, query) {
		return api
			.get("/class-library/classes", {params: {page: page, limit: limit, q: query}})
			.then(response => response.data.map(el => ({key: el.id, ...el})));
	}
}

export function ClassLibraryComponent({selectedClass, setSelectedClass}) {
	const [dataSource, setDataSource] = useState(new ClassesDataSource());

	const handleSelectClassClick = item => {
		setSelectedClass(item);
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			render: (id, item) => (
				<div
					className="beautiful-link"
					style={{cursor: "pointer", float: "left"}}
					onClick={_ => {
						handleSelectClassClick(item);
					}}>
					{item.name}
				</div>
			),
		},
		{
			title: "Type",
			dataIndex: "type",
		},
	];

	const coloredColumns = new ColoredColumns(columns, record =>
		record.id === selectedClass?.id ? "#eee" : "",
	).values();

	return (
		<DataCard style={{paddingTop: 65}} title={"Class Library"}>
			<ManualPagedTable id="classes-table" size="small" columns={coloredColumns} asyncDataSource={dataSource} />
		</DataCard>
	);
}

export class ColoredColumns {
	constructor(columns, color) {
		this._columns = columns;

		if (typeof color === "function") {
			this._color = color;
		} else {
			this._color = () => color;
		}
	}

	values() {
		return this._columns.map(col => ({
			...col,
			onCell: (record, rowIndex) => {
				let origin = {};
				if (col.onCell) {
					origin = {...col.onCell(record, rowIndex)};
				}
				return {
					...origin,
					style: {
						background: this._color(record, rowIndex),
						...origin?.style,
					},
				};
			},
		}));
	}
}
