import React, {useEffect, useState} from "react";

import "./QAManager.css";

import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import * as _ from "lodash";

import api from "../api";
import * as Auth from "../AuthService";

import "../index.less";

export default function Metrics({item, finalValuesPositions, visibleMetrics}) {
	const [tokenCompleteness, setTokenCompleteness] = useState(0);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (visibleMetrics) {
			getTokenCompleteness();
		}
	}, [visibleMetrics]);

	function getTokenCompleteness() {
		setLoading(true);
		const valuesToCheck = {
			final_values: finalValuesPositions,
		};
		api.post(`/metrics?item_id=${item._id}`, valuesToCheck, Auth.createConfig())
			.then(json => {
				setTokenCompleteness(json.data.items.token_completeness);
			})
			.catch(error => console.log(error))
			.finally(() => setLoading(false));
	}

	return (
		<div>
			Token completeness:{" "}
			{loading ? (
				<Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin />} />
			) : (
				_.round(tokenCompleteness, 2)
			)}
		</div>
	);
}
