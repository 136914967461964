import React from "react";

import {Dropdown, Menu} from "antd";

const menu = (value, attr, clickHandler, beautifier, options, index) => (
	<Menu>
		{options.map((option, i) => (
			<Menu.Item onClick={() => clickHandler(option)}>
				Set {option} as a {index + 1} option for {attr}
			</Menu.Item>
		))}
	</Menu>
);

export function ContextMenu({doc, children, attr, clickHandler, beautifier, options, index}) {
	return (
		<Dropdown overlay={menu(doc, attr, clickHandler, beautifier, options, index)} trigger={["contextMenu"]}>
			{children}
		</Dropdown>
	);
}
