import React from "react";
import { BiSolidFolderPlus,BiSolidFolderMinus } from "react-icons/bi";

const IconClassLibrary = ({ isOpen, mainFolder, onClick }) => {

    const Icon = isOpen ? BiSolidFolderMinus : BiSolidFolderPlus;
    return (
        <div>
            {mainFolder ? < BiSolidFolderMinus fontSize={24} style={{ marginRight: 2 }} /> : <Icon fontSize={24} style={{ marginRight: 2 }} onClick={onClick} />}
        </div>
    )
}

export default IconClassLibrary;