import React, {useRef, useState} from "react";

import {AutoComplete} from "antd";
import {PiPencilSimpleLineBold} from "react-icons/pi";

const CustomAutoComplete = ({id, value, onChange, onSelect, options, placeholder, onClean, disabled, emptyDefault}) => {
	const autocomplete = useRef(null);
	const needBackValue = useRef(false);

	const [isOpen, setIsOpen] = useState(false);

	const [prevValue, setPrevValue] = useState("");
	const [isCleanBtnClicked, setIsCleanBtnClicked] = useState(false);

	const handleSelectAndClose = value => {
		onSelect(value);
		needBackValue.current = false;
		if (autocomplete.current) {
			autocomplete.current.blur();
		}
	};

	const handleFocus = () => {
		needBackValue.current = true;
		setPrevValue(value);
		setIsOpen(true);
	};

	const handleCleanBtn = () => {
		setIsCleanBtnClicked(true);
		if (autocomplete.current) {
			autocomplete.current.focus();
		}
		onClean();
	};

	const handleBlur = () => {
		if (!isOpen) {
			return;
		}

		if (isCleanBtnClicked) {
			if (needBackValue.current) {
				onChange(prevValue);
				needBackValue.current = false;
			}
			setIsCleanBtnClicked(false);
		} else if (needBackValue.current) {
			if (emptyDefault && !prevValue) {
				onChange(prevValue);
			} else if (prevValue) {
				onChange(prevValue);
			}
		}
		setPrevValue("");
		setIsOpen(false);
	};

	return (
		<div className="navigation-elem">
			<AutoComplete
				id={id}
				ref={autocomplete}
				options={options}
				value={value}
				onChange={onChange}
				style={{width: "95%", color: "var(--primary-color)", borderBottom: "1px solid #959595"}}
				placeholder={placeholder}
				onSelect={handleSelectAndClose}
				onBlur={handleBlur}
				open={isOpen}
				onFocus={handleFocus}
				filterOption={(inputValue, option) => option.value.toUpperCase().includes(inputValue.toUpperCase())}
				disabled={disabled}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
			/>
			<PiPencilSimpleLineBold style={{fontSize: "22px", cursor: "pointer"}} onClick={handleCleanBtn} />
		</div>
	);
};

export default CustomAutoComplete;
