import React, {useEffect, useState} from "react";

import "./SourceTable.css";

import {Dropdown, Menu as DropdownMenu, Spin, Table, Tag} from "antd";
import FileDownload from "js-file-download";
import {Button} from "react-bootstrap";

import api from "../api";
import * as Auth from "../AuthService";
import {properties} from "../properties";

import "../index.less";

function EntityLinksDropdown({material, tagNumber, type, children, batch}) {
	const items = (
		<DropdownMenu>
			<DropdownMenu.Item>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={`${properties.analyticsLink}/projects/6295c7173ae8947d89af2c73/min-max-material-analysis?material=${material}`}>
					Workbench
				</a>
			</DropdownMenu.Item>
			<DropdownMenu.Item>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={`${properties.ihLink}/data?operator=Client&material=${material}`}>
					Inventory
				</a>
			</DropdownMenu.Item>
		</DropdownMenu>
	);

	return <Dropdown overlay={items}>{children}</Dropdown>;
}

export default function SourceTable(props) {
	const [loadingAttributeExtraction, setLoadingAttributeExtraction] = useState(false);
	const [loadingStatistics, setLoadingStatistics] = useState(false);
	const [rows, setRows] = useState([]);

	function onChange(pagination, _filters, sorter) {
		if (_filters) {
			if (
				_filters.tags &&
				((_filters.tags.includes("verified") && _filters.tags.includes("unverified")) ||
					(_filters.tags.includes("partially verified") && _filters.tags.includes("unverified")) ||
					(_filters.tags.includes("partially verified") && _filters.tags.includes("verified")))
			) {
				props.setSource([]);
				props.setTagsFilter(_filters.tags);
				return;
			}
			props.onChangeTable(pagination.current, _filters.tags);
		}
	}

	const exportFullExtraction = () => {
		setLoadingAttributeExtraction(true);
		const config = Auth.createConfig();
		config["responseType"] = "blob";
		api.get(`/batch/full/export?batch=${props.batch}&class=${props.class}`, config)
			.then(response => {
				const header = response.headers["content-disposition"];
				const filename = /filename=(.*)/.exec(header)[1];
				FileDownload(response.data, filename, filename);
			})
			.catch(error => {
				console.log(error);
				alert("Sorry, something went wrong.");
			})
			.finally(() => setLoadingAttributeExtraction(false));
	};

	const exportStatistics = () => {
		setLoadingStatistics(true);
		const config = Auth.createConfig();
		config["responseType"] = "blob";
		api.get(`/report/statistic?batch=${props.batch}&class=${props.class}`, config)
			.then(response => {
				const header = response.headers["content-disposition"];
				const filename = /filename=(.*)/.exec(header)[1];
				FileDownload(response.data, filename, filename);
			})
			.catch(error => {
				console.log(error);
				alert("Sorry, something went wrong.");
			})
			.finally(() => setLoadingStatistics(false));
	};

	const columns = [
		{
			title: "#",
			dataIndex: "index",
			key: "index",
		},
		{
			title: "Class",
			dataIndex: "class_name",
			key: "class",
		},
		{
			title: "Item",
			dataIndex: "item",
			key: "item",
			render: item => <p className="sourceTable-link">{item}</p>,
		},
		{
			title: "Description",
			key: "description",
			dataIndex: "description",
		},
		{
			title: "Tags",
			key: "tags",
			dataIndex: "tags",
			filters: [
				{
					text: "Annotated",
					value: "annotated",
				},
				{
					text: "Verified",
					value: "verified",
				},
				{
					text: "Partially Verified",
					value: "partially verified",
				},
				{
					text: "Unverified",
					value: "unverified",
				},
			],
			filteredValue: props.tagsFilter,
			render: tags => (
				<>
					{tags.map(tag => {
						let color = null;
						switch (tag) {
							case "annotated":
								color = "blue";
								break;
							case "verified":
								color = "green";
								break;
							case "partially verified":
								color = "orange";
								break;
							default:
								color = "grey";
								break;
						}
						return (
							<Tag color={color} key={tag}>
								{tag.toUpperCase()}
							</Tag>
						);
					})}
				</>
			),
		},
		{
			title: "Actions",
			key: "actions",
			dataIndex: "actions",
			render: actions => (
				<>
					{actions.map(action => (
						<p className="sourceTable-link">{action.component}</p>
					))}
				</>
			),
		},
	];

	useEffect(() => {
		setRows(
			props.source.map((value, index) => {
				const item = {};
				item.index = index + 1;
				item.class_name = value.class_name;
				item.item = (
					<EntityLinksDropdown
						material={value.item}
						batch={props.batch}
						type={"material"}
						children={<div>{value.item}</div>}
					/>
				);
				item.description = value.description;
				item.tags = [];
				if (value.manualMarkups) {
					item.tags.push("annotated");
				}
				if (value.final_attributes == value.attributes.length) {
					item.tags.push("verified");
				} else if (value.final_attributes) {
					item.tags.push("partially verified");
				} else if (value.final_attributes == 0) {
					item.tags.push("unverified");
				}

				item.actions = [];
				item.actions.push({component: <a onClick={() => props.handleAnnotation(value)}>Annotate</a>});
				item.actions.push({
					component: (
						<a
							onClick={() => {
								props.openQA(value);
								props.setItemTags(item.tags);
							}}>
							QA
						</a>
					),
				});
				return item;
			}),
		);
	}, [props.source]);

	const exportButton = (
		<Button
			className="primary-button"
			style={{float: "none"}}
			size={"sm"}
			onClick={exportFullExtraction}
			disabled={loadingAttributeExtraction}>
			{loadingAttributeExtraction ? "Getting Extractions... " : "Attribute Extraction"}{" "}
			{loadingAttributeExtraction && <Spin />}
		</Button>
	);

	const exportStatisticsButton = (
		<Button
			className="primary-button"
			style={{float: "none"}}
			size={"sm"}
			onClick={() => exportStatistics(props.batch, props.batchSize, props.approved)}
			disabled={loadingStatistics}>
			{loadingStatistics ? "Getting Statistics... " : "Export Statistics"} {loadingStatistics && <Spin />}
		</Button>
	);

	return (
		<div>
			<div style={{marginTop: 5}}>
				{props.class ? (
					<div>
						Results for class: <b>{props.class}</b> (batch <b>{props.batch}</b>){exportButton}{" "}
						{exportStatisticsButton}
					</div>
				) : (
					<div>
						Results for batch: <b>{props.batch}</b>
						{exportButton} {exportStatisticsButton}
					</div>
				)}
			</div>
			<div>
				Total number of items in the batch: <b>{props.batchSize.toLocaleString()}</b> (Verified:{" "}
				<b>{props.approved.toLocaleString()}</b>)
			</div>
			<Table
				className="primary-table"
				columns={columns}
				dataSource={rows}
				onChange={onChange}
				pagination={{
					position: "bottomCenter",
					showSizeChanger: false,
					current: props.current,
					total: props.total,
					pageSize: properties.previewRowsPerPage,
				}}
			/>
		</div>
	);
}
