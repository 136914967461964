import axios from "axios";

import Auth from "./Auth";

const baseURL = process.env.REACT_APP_HOST + "/api";

const get = async (uri, config = {}, cachRequest = false) => {
	const headers = _withHeaders(config);

	const response = await axios.get(baseURL + uri, headers);

	if (response.status >= 200 && response.status < 300) {
		return response;
	} else {
		throw new Error(`Response with status ${response.status}: ${response.statusText}`);
	}
};

const post = async (uri, data = {}, config = {}) => {
	const response = await axios.post(baseURL + uri, data, _withHeaders(config));

	if (response.status >= 200 && response.status < 300) {
		return response;
	} else {
		throw new Error(`Response with status ${response.status}: ${response.statusText}`);
	}
};

const put = async (uri, data = {}, config = {}) => {
	const response = await axios.put(baseURL + uri, data, _withHeaders(config));

	if (response.status >= 200 && response.status < 300) {
		return response;
	} else {
		throw new Error(`Response with status ${response.status}: ${response.statusText}`);
	}
};

const del = async (uri, config = {}) => {
	const response = await axios.delete(baseURL + uri, _withHeaders(config));

	if (response.status >= 200 && response.status < 300) {
		return response;
	} else {
		throw new Error(`Response with status ${response.status}: ${response.statusText}`);
	}
};

const _withHeaders = config => ({
	headers: Auth.headers(),
	...config,
});

const api = {
	get,
	post,
	put,
	delete: del,
};

export default api;
