import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {Switch, withRouter} from "react-router";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";

import "./index.css";

import {Auth, UIConfigContext} from "@digatex/digatex-ui-lib";
import axios from "axios";
import {ConnectedRouter, connectRouter, routerMiddleware} from "connected-react-router";
import {createBrowserHistory} from "history";

import App from "./AppComponent/App";
import {CurrentUserProvider} from "./contexts/CurrentUserContext";
import DataAnalyzer from "./DataAnalyzerComponent/DataAnalyzer";
import ExtractManager from "./ExtractManagerComponent/ExtractManager";
import {MenuLayout} from "./layouts/MenuLayout";
import {ProjectLayout} from "./layouts/ProjectLayout";
import {RouteWithLayout} from "./layouts/RouteWithLayout";
import {MaturitySourceRoot} from "./Maturity Sources/MaturitySourceRoot";
import {JIRA_DATA_KEY, UI_CONFIG} from "./properties";
import {Register} from "./Register/Register";
import {Reports} from "./Reports";
import * as serviceWorker from "./serviceWorker";
import AdminPageRoot from "./SettingsComponent/Settings";

import "./index.less";

const history = createBrowserHistory();

function configureStore(preloadedState) {
	return createStore(
		combineReducers({
			router: connectRouter(history),
		}),
		preloadedState,
		compose(applyMiddleware(routerMiddleware(history))),
	);
}

axios.interceptors.request.use(config => {
	if (Auth.isAuthenticated()) {
		const cb = () => {
			config.headers = {...config.headers, ...Auth.headers()};
			return Promise.resolve(config);
		};
		return Auth.updateToken(cb);
	}
});

export const store = configureStore();

function jiraHelpdesk(callback) {
	var jhdScript = document.createElement("script");
	jhdScript.type = "text/javascript";
	jhdScript.setAttribute("data-jsd-embedded", null);
	jhdScript.setAttribute("data-key", JIRA_DATA_KEY);
	jhdScript.setAttribute("data-base-url", "https://jsd-widget.atlassian.com");
	jhdScript.src = "https://jsd-widget.atlassian.com/assets/embed.js";
	if (jhdScript.readyState) {
		jhdScript.onreadystatechange = function () {
			if (jhdScript.readyState === "loaded" || jhdScript.readyState === "complete") {
				jhdScript.onreadystatechange = null;
				callback();
			}
		};
	} else {
		jhdScript.onload = function () {
			callback();
		};
	}
	document.head.appendChild(jhdScript);
}
jiraHelpdesk(function () {
	const DOMContentLoaded_event = new Event("DOMContentLoaded", {bubbles: true, cancelable: true});
	window.document.dispatchEvent(DOMContentLoaded_event);
});

function DMRouter() {
	return (
		<div>
			<Switch>
				<RouteWithLayout layout={ProjectLayout} path="/dm/extract-manager" component={ExtractManager} />
				<RouteWithLayout layout={ProjectLayout} path="/dm/data-analyzer" component={DataAnalyzer} />
				<RouteWithLayout layout={ProjectLayout} path="/dm/admin" component={AdminPageRoot} />
				<RouteWithLayout layout={ProjectLayout} path="/dm*" component={App} />
				<RouteWithLayout layout={MenuLayout} exact path="/maturity-source" component={MaturitySourceRoot} />
				<RouteWithLayout layout={MenuLayout} exact path="/register" component={Register} />
				<RouteWithLayout layout={MenuLayout} exact path="/reports" component={Reports} />
				<RouteWithLayout layout={MenuLayout} path="/*" />
			</Switch>
		</div>
	);
}

const Router = withRouter(DMRouter);

Auth.init(() =>
	ReactDOM.render(
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<UIConfigContext.Provider value={UI_CONFIG}>
					<CurrentUserProvider>
						<Router />
					</CurrentUserProvider>
				</UIConfigContext.Provider>
			</ConnectedRouter>
		</Provider>,
		document.getElementById("root"),
	),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
