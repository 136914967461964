import React from "react";

import {Tabs} from "antd";

import AttributeAnalyser from "./AttributeAnalyzer/AttributeAnalyzer";

export default function AnalysisAgent({batch, clsName, searchExamples}) {
	const items = [
		// {
		//     key: 'extract',
		//     label: 'Extraction Results',
		//     children: <h1>In Progress...</h1>,
		// },
		clsName
			? {
					key: "attribute-analyzer",
					label: "Attribute Analyser",
					children: <AttributeAnalyser batch={batch} clsName={clsName} searchExamples={searchExamples} />,
				}
			: null,
	];

	return <Tabs defaultActiveKey="extract" items={items} type="card" className="primary-card analysis-card" />;
}
