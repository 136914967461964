import React, {useEffect, useState} from "react";

import "./ExamplesTableAttributeAnalyzer.css";

import {Button, Dropdown, Menu, Table, Tag} from "antd";

import {properties} from "../../properties";

function EntityLinksDropdown({material, children}) {
	const items = (
		<Menu>
			<Menu.Item>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={`${properties.dmLink}/dm?search=${material}&batch=${"FULL_MATERIALS"}`}>
					Engineering
				</a>
			</Menu.Item>
			<Menu.Item>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={`${properties.analyticsLink}/projects/6295c7173ae8947d89af2c73/min-max-material-analysis?material=${material}`}>
					Workbench
				</a>
			</Menu.Item>
			<Menu.Item>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={`${properties.ihLink}/data?operator=Client&material=${material}`}>
					Inventory
				</a>
			</Menu.Item>
		</Menu>
	);

	return <Dropdown overlay={items}>{children}</Dropdown>;
}

export default function ExamplesTableAttributeAnalyzer({dataSource, page, setPage, searchExamples}) {
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const columns = [
		{
			title: "Item",
			dataIndex: "item",
			width: 50,
			render: item => (
				<EntityLinksDropdown material={item} children={<p className="example-table-item-link">{item}</p>} />
			),
		},
		{
			title: "Class",
			dataIndex: "class",
			width: 50,
		},
		{
			title: "Description",
			dataIndex: "description",
			render: description => <span dangerouslySetInnerHTML={{__html: description}} />,
			width: 500,
		},
		{
			title: "Value",
			dataIndex: "value",
			render: value => <p style={{margin: 5, whiteSpace: "break-spaces", wordBreak: "break-word"}}>{value}</p>,
			width: 150,
		},
		{
			title: "Source",
			dataIndex: "source",
			render: source => <Tag color="blue">{source.toUpperCase()}</Tag>,
			width: 100,
		},
	];

	const clearSelectedKeys = () => {
		setSelectedRowKeys([]);
		// searchExamples("", false)
	};

	useEffect(
		() => () => {
			setSelectedRowKeys([]);
		},
		[dataSource],
	);

	return (
		<Table
			className="analysis-table-examples primary-table"
			tableLayout="fixed"
			rowKey="item"
			columns={columns}
			dataSource={dataSource}
			rowSelection={{
				type: "checkbox",
				selectedRowKeys: selectedRowKeys,
				onChange: setSelectedRowKeys,
			}}
			pagination={{
				current: page,
				onChange: page => setPage(page),
				pageSize: 10,
				showSizeChanger: false,
				showTotal: () => (
					<>
						<Button
							type="primary"
							className="btn-primary"
							onClick={() => searchExamples(selectedRowKeys.join(" "), true)}>
							Apply search
						</Button>
						<Button type="primary" className="btn-primary" onClick={clearSelectedKeys}>
							Clear
						</Button>
					</>
				),
			}}
		/>
	);
}
