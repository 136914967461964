import React, {memo, useCallback, useEffect, useRef, useState} from "react";

import "./NavigationBar.css";

import Image from "react-bootstrap/Image";
import {Scrollbars} from "react-custom-scrollbars";

import CustomAutoComplete from "./CustomAutoComplete";
import * as Auth from "../AuthService";
import api from "../NewApi";
import {BATCH_WITH_ALL_MATERIALS, properties} from "../properties";
import IconClassLibrary from "./IconClassLibrary";

class NavigationBarRule extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
		};
	}

	render() {
		return (
			<div className="Navigation-attr">
				<a
					style={{color: "var(--primary-color)", marginLeft: 24}}
					onClick={event => {
						event.preventDefault();
						this.props.onRuleSelect(this.props.rule._id);
					}}
					href={`/extract-manager`}>
					<i> {this.props.rule.name}</i>
				</a>
				<Image onClick={() => this.props.deleteRule()} src={"/images/close-black.png"} />
			</div>
		);
	}
}

class NavigationBarAttribute extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			rules: [],
		};
	}

	handleClick(event) {
		event.preventDefault();
		if (!this.state.isOpen) {
			if (this.state.rules.length === 0) {
				api.get(`/rules?class=${this.props.class.className}&attr=${this.props.attr}`, Auth.createConfig())
					.then(json => this.setState({rules: json.data.result}))
					.catch(error => console.log(error));
			}
		}
		this.setState({isOpen: !this.state.isOpen});
	}

	render() {
		const open_icon = (
			<div style={{overflowX: "hidden", display: "flex", alignItems: "center"}}>
				<IconClassLibrary isOpen={this.state.isOpen} onClick={event => this.handleClick(event)} mainFolder={false} />
				<a
					style={{
						color:
							this.props.current.attrName === this.props.attr
								? "var(--primary-color)"
								: `${properties.colors.black}`,
					}}
					onClick={event => {
						event.preventDefault();
						if (this.props.attr !== "IGNORE LIST") {
							this.props.handleAttributes(this.props.attr);
						}
					}}
					href={`/extract-manager`}>
					{this.props.attr !== "IGNORE LIST" ? <i>{this.props.attr}</i> : <i>[ {this.props.attr} RULES ]</i>}
				</a>
			</div>
		);

		const rules = this.state.rules
			? this.state.rules.map((v, i) => (
					<NavigationBarRule
						rule={v}
						key={i}
						deleteRule={() => this.props.deleteRule(v)}
						onRuleSelect={rule => this.props.onRuleSelect(rule)}
					/>
				))
			: [];

		return (
			<div className="Navigation-attr">
				{open_icon}
				{this.state.isOpen && this.props.attr !== "IGNORE LIST" && (
					<a
						style={{color: `${properties.colors.black}`, marginLeft: 24}}
						className="Navigation-attr"
						onClick={event => {
							event.preventDefault();
							this.props.addRule();
						}}>
						Add rule
					</a>
				)}
				{this.state.isOpen ? rules : null}
			</div>
		);
	}
}

class NavigationBarClass extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			mapped: [],
			attributes: [],
		};
	}

	handleClick(event) {
		event.preventDefault();
		if (!this.state.isOpen) {
			if (this.state.attributes.length === 0) {
				api.get(`/attributes?class=${this.props.class.className}`, Auth.createConfig())
					.then(json => this.setState({attributes: json.data.items}))
					.catch(error => console.log(error));
			}
			if (this.state.mapped.length === 0) {
				this.get_mapping_rules();
			}
		}
		this.setState({isOpen: !this.state.isOpen});
	}

	get_mapping_rules() {
		api.get(`/mapping/rules/get?class=${this.props.class.className}`, Auth.createConfig())
			.then(json => this.setState({mapped: json.data}))
			.catch(error => console.log(error));
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.shouldUpdate !== this.props.shouldUpdate) {
			if (this.state.attributes) {
				this.setState({attributes: [], mapped: [], isOpen: false});
			}
		}
		// if (prevProps.active !== this.props.active && this.props.active) {
		//     document.getElementById(`#${this.props.class.className.replace(/ /g, '_')}`).scrollIntoView(true)
		// }
	}

	render() {
		const open_icon = (
			<div id={`#${this.props.class.className.replace(/ /g, "_")}`} style={{ display: "flex", alignItems: "center"}} >
				<IconClassLibrary isOpen={this.state.isOpen} onClick={event => this.handleClick(event)} mainFolder={false} />
				<a
					style={{
						color: this.props.active ? "var(--primary-color)" : `${properties.colors.black}`,
						pointerEvents: "auto",
					}}
					onClick={event => {
						event.preventDefault();
						this.props.handleClick();
					}}
					id={`#${this.props.class.className.replace(/ /g, "_")}`}>
					{this.props.class.className} ({this.props.class.count}: {this.props.class.countApproved || "..."})
				</a>
			</div>
		);

		const attributes = this.state.attributes.map((v, i) => (
			<NavigationBarAttribute
				current={this.props.current}
				handleAttributes={attr =>
					this.props.handleAttributes({attrName: attr, class: this.props.class.className})
				}
				attr={v.attributeName}
				onRuleSelect={rule => this.props.onRuleSelect(rule)}
				rules={v.rules}
				deleteRule={rule => this.props.deleteRule(v, rule)}
				key={i}
				addRule={() => this.props.addRule(v.attributeName)}
				batch={this.props.batch}
				class={this.props.class}
			/>
		));

		const rules =
			Array.isArray(this.state.mapped) &&
			this.state.mapped.map((v, i) => (
				<NavigationBarRule
					rule={v}
					key={i}
					deleteRule={() => this.props.deleteRule("", v)}
					onRuleSelect={rule => this.props.onRuleSelect(rule)}
				/>
			));

		return (
			<div className="Navigation-class">
				{open_icon}
				{this.state.isOpen ? rules : null}
				{this.state.isOpen && (
					<a
						style={{color: "var(--primary-color)", marginLeft: 24}}
						className="Navigation-attr"
						onClick={event => {
							event.preventDefault();
							this.props.addRule("");
						}}>
						Add rule
					</a>
				)}
				{this.state.isOpen ? attributes : null}
			</div>
		);
	}
}

const NavigationBarClassLibrary = memo(
	({
		batch,
		classes,
		shouldUpdate,
		item,
		attr,

		changeClass,

		callbackAddRule,
		callbackDeleteRule,
		callbackOnRuleSelect,
		callbackHandleClassChange,
		callbackHandleAttributes,
	}) => {
		const scrollbarsRef = useRef(null);

		const renderedClasses = classes.map((v, i) => (
			<NavigationBarClass
				key={i}
				current={attr}
				handleAttributes={callbackHandleAttributes}
				active={v.className === item}
				addRule={attributeName => callbackAddRule(v, attributeName)}
				shouldUpdate={shouldUpdate}
				deleteRule={(attr, rule) => callbackDeleteRule(v, attr, rule)}
				onRuleSelect={callbackOnRuleSelect}
				handleClick={() => {
					callbackHandleClassChange(v.className);
					changeClass(v.className);
				}}
				batch={batch}
				class={v}
			/>
		));

		const scrollToElement = className => {
			const element = document.getElementById(`#${className.replace(/ /g, "_")}`);
			if (element && scrollbarsRef.current) {
				const elementTop = element.offsetTop;
				scrollbarsRef.current.scrollTop(elementTop);
			}
		};

		const open_icon = (
			<div style={{ display: "flex", alignItems: "center" }}>
				<IconClassLibrary mainFolder={true}/>
				<span
					onClick={() => {
						callbackHandleClassChange("");
						changeClass("");
					}}
					style={{fontWeight: "bold"}}>
					Class Library
				</span>
			</div>
		);

		useEffect(() => {
			if (item) {
				scrollToElement(item);
			}
		}, [item]);

		return (
			<div className="library">
				<Scrollbars ref={scrollbarsRef} autoHeight autoHeightMin={20} autoHeightMax={"calc(100vh - 11.7em)"}>
					{open_icon}
					{batch && renderedClasses}
				</Scrollbars>
			</div>
		);
	},
);

const NavigationBarRoot = ({
	item,
	batch,
	approvedByClass,
	shouldUpdate,
	windowHeight,
	attr,
	handleAttributes,
	addRule,
	deleteRule,
	onRuleSelect,
	handleClassChange,
	handleBatchChange,
}) => {
	const [optionsBatches, setOptionsBatches] = useState([]);
	const [currentBatch, setCurrentBatch] = useState();

	const [optionsClasses, setOptionsClasses] = useState([]);
	const [currentClass, setCurrentClass] = useState();

	const [classes, setClasses] = useState([]);

	const callbackAddRule = useCallback((classObj, attr) => {
		addRule(classObj, attr);
		setCurrentClass(classObj.className);
	}, []);
	const callbackDeleteRule = useCallback((classObj, attr, rule) => deleteRule(classObj, attr, rule), []);
	const callbackOnRuleSelect = useCallback(rule => onRuleSelect(rule), []);

	const callbackHandleClassChange = useCallback(className => handleClassChange(className), []);
	const callbackHandleAttributes = useCallback(attr => handleAttributes(attr), []);

	const redirectIfNeeded = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const batchParam = urlParams.get("batch");

		if (batchParam === "material-data-mapper" || batchParam === "equipment-data-mapper") {
			urlParams.set("batch", "FULL_MATERIALS");
			const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
			window.history.replaceState(null, "", newUrl);
		}
	};

	const fetchClasses = async () => {
		try {
			const re = await api.get(`/classes?batch=${batch}`, Auth.createConfig());
			const classesData = re.data;

			setOptionsClasses(
				classesData.map(v => ({
					value: v.className,
					label: v.className,
				})),
			);
			setClasses(classesData);
		} catch (error) {
			console.log("Error while calling fetchClasses:", error.message);
		}
	};

	const getBatches = async () => {
		try {
			const re = await api.get("/batches", Auth.createConfig());
			const batchesData = re.data;

			setOptionsBatches(
				batchesData.items.map(v => ({
					value: v,
					label: v,
				})),
			);

			let batchParam = new URLSearchParams(window.location.search.replace("%", "&")).get("batch");
			if (!batchParam) {
				batchParam = BATCH_WITH_ALL_MATERIALS;
			}

			handleBatchChange(batchParam);
			setCurrentBatch(batchParam);
		} catch (error) {
			console.log("Error while calling getBatches:", error.message);
		}
	};

	const handleClassSelect = className => {
		setCurrentClass(className);
		if (className) {
			handleClassChange(className);
		}
	};

	const handleBatchSelect = newBatch => {
		handleBatchChange(newBatch);
		setCurrentBatch(newBatch);

		if (newBatch !== batch) {
			setClasses([]);
			setCurrentClass("");
		}
	};

	useEffect(() => {
		redirectIfNeeded();
		getBatches();
	}, []);

	useEffect(() => {
		if (batch) {
			fetchClasses();
		}
	}, [batch]);

	useEffect(() => {
		if (approvedByClass) {
			setClasses(prevClassesState =>
				prevClassesState.map(prevClass => ({
					...prevClass,
					countApproved: approvedByClass[prevClass.className] || "0",
				})),
			);
		}
	}, [approvedByClass]);

	return (
		<div>
			<div className="Navigation-Bar">
				<CustomAutoComplete
					id={"combo-box-batch"}
					options={optionsBatches}
					value={currentBatch}
					onChange={setCurrentBatch}
					placeholder={"Search for batches"}
					onSelect={handleBatchSelect}
					onClean={() => setCurrentBatch("")}
					emptyDefault={false}
				/>

				<CustomAutoComplete
					id={"combo-box-demo"}
					options={optionsClasses}
					value={currentClass}
					onChange={setCurrentClass}
					placeholder={"Search for classes"}
					onSelect={handleClassSelect}
					onClean={() => setCurrentClass("")}
					emptyDefault={!item}
				/>
			</div>
			<NavigationBarClassLibrary
				batch={batch}
				classes={classes}
				shouldUpdate={shouldUpdate}
				item={item}
				attr={attr}
				changeClass={setCurrentClass}
				callbackAddRule={callbackAddRule}
				callbackDeleteRule={callbackDeleteRule}
				callbackHandleClassChange={callbackHandleClassChange}
				callbackHandleAttributes={callbackHandleAttributes}
				callbackOnRuleSelect={callbackOnRuleSelect}
			/>
		</div>
	);
};

export default NavigationBarRoot;
