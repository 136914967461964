import React from "react";

import "./QAManager.css";

import {Col, Row} from "antd";

function handleGoogleSearch(query) {
	return `https://www.google.com/search?q=${encodeURIComponent(query)}`;
}

export default function GeneralAttributes({item, classInfo}) {
	const partNumber = item.part_number;
	const manufName = item.manuf_name;
	const classId = classInfo.class_id;

	return (
		<Row>
			<Col span={8} align="center">
				PART NUMBER:{" "}
				<b>
					{partNumber === "" || partNumber === "N/A" || partNumber === "NA" ? (
						"N/A"
					) : (
						<a href={handleGoogleSearch(partNumber)} target="_blank" rel="noopener noreferrer">
							{partNumber}
						</a>
					)}
				</b>
			</Col>
			<Col span={8} align="center">
				MANUFACTURE NAME:{" "}
				<b>
					{manufName === "" || manufName === "N/A" || manufName === "NA" ? (
						"N/A"
					) : (
						<a href={handleGoogleSearch(manufName)} target="_blank" rel="noopener noreferrer">
							{manufName}
						</a>
					)}
				</b>
			</Col>
			<Col span={8} align="center">
				Class ID: <b>{classId === "" || classId === "N/A" || classId === "NA" ? "N/A" : classId}</b>
			</Col>
		</Row>
	);
}
