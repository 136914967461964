import React, {useCallback, useEffect, useState} from "react";

import "./QATable.css";

import {CheckOutlined, ClearOutlined, CloseOutlined} from "@ant-design/icons";
import {Table} from "antd";

import "../index.less";

const DefaultCell = ({className, value, title = null, onClick = null}) => (
	<div className={className} title={title ? title : ""} onClick={onClick}>
		{value}
	</div>
);

const EditFinalCell = ({
	className,
	finalValue,
	attrName,
	refreshFinalValue,
	annotatedValue,
	regexValue,
	llmExtractedValue,
	setFinal,
	saveFinal,
	setEdit,
	finalAttributeCheck,
}) => (
	<div className={className}>
		<input
			onChange={event => setFinal(event.target.value)}
			onKeyDown={e => (e.key === "Enter" ? saveFinal(finalValue, attrName) : null)}
			value={finalValue ? finalValue : ""}
		/>
		<CheckOutlined onClick={() => saveFinal(finalValue, attrName)} style={{fontSize: 24, color: "limegreen"}} />
		{finalAttributeCheck && (
			<ClearOutlined
				onClick={() => {
					let inputs = {};
					if (annotatedValue) {
						inputs = {value: annotatedValue, field: "annotation"};
					} else if (regexValue) {
						inputs = {value: regexValue, field: "regexExtraction"};
					} else if (llmExtractedValue) {
						inputs = {value: llmExtractedValue, field: "llmExtraction"};
					}
					refreshFinalValue(inputs.value, attrName, inputs.field);
					setEdit(false);
				}}
				style={{fontSize: 24, color: "black"}}
			/>
		)}
		<CloseOutlined
			onClick={() => {
				setEdit(false);
				setFinal(finalValue);
			}}
			style={{fontSize: 24, color: "red"}}
		/>
	</div>
);

const FinalCell = ({
	className,
	value,
	attrName,
	saveFinalAttribute,
	refreshFinalValue,
	annotatedValue,
	regexValue,
	llmExtractedValue,
	finalAttributeCheck,
	onClick,
}) => {
	const [finalValue, setFinal] = useState(value === "-" ? "" : value);
	const [edit, setEdit] = useState(false);

	useEffect(() => {
		setFinal(value === "-" ? "" : value);
	}, [value]);

	const saveFinal = (finalValue, attrName) => {
		saveFinalAttribute(finalValue, attrName);
		setEdit(false);
	};

	return (
		<div onDoubleClick={() => setEdit(true)}>
			{edit ? (
				<EditFinalCell
					className={className}
					finalValue={finalValue}
					attrName={attrName}
					refreshFinalValue={refreshFinalValue}
					annotatedValue={annotatedValue}
					regexValue={regexValue}
					llmExtractedValue={llmExtractedValue}
					setFinal={setFinal}
					saveFinal={saveFinal}
					setEdit={setEdit}
					finalAttributeCheck={finalAttributeCheck}
				/>
			) : (
				<DefaultCell className={className} value={value} onClick={onClick} />
			)}
		</div>
	);
};

export default function QATable({
	attributes,
	annotatedValues,
	regexExtractionValues,
	llmExtractionValues,
	finalValues,
	standardValues,
	saveFinalAttribute,
	refreshFinalValue,
	showAttribute,
	handleSelectCell,
}) {
	const [dataSource, setDataSource] = useState([]);

	const getDataSource = useCallback(() => {
		const data = [];
		attributes.forEach((attrObj, index) => {
			const attrName = attrObj.attributeName;
			data.push({
				key: index,
				attribute: attrObj,
				annotatedValues: annotatedValues && annotatedValues[attrName] ? annotatedValues[attrName] : "-",
				regexExtractionValues:
					regexExtractionValues && regexExtractionValues[attrName] ? regexExtractionValues[attrName] : "-",
				llmExtractionValues:
					llmExtractionValues && llmExtractionValues[attrName] ? llmExtractionValues[attrName] : "-",
				standardValues: standardValues && standardValues[attrName] ? standardValues[attrName] : "-",
				finalValue: finalValues && finalValues[attrName] ? finalValues[attrName].value : "-",
			});
		});
		return data;
	}, [attributes, annotatedValues, regexExtractionValues, llmExtractionValues, standardValues, finalValues]);

	useEffect(() => {
		setDataSource(getDataSource());
	}, [getDataSource]);

	const columns = [
		{
			title: "Attribute",
			dataIndex: "attribute",
			key: "attribute",
			className: "attribute",
			width: 1.4,
			render: (attribute, record) => {
				const className = attribute.required ? "bold" : "";
				const title = attribute.attr_id;
				const attrName = attribute.attributeName;
				const onClick = () => showAttribute(attrName);
				return <DefaultCell className={className} title={title} value={attrName} onClick={onClick} />;
			},
		},
		{
			title: "Annotation Based Value",
			dataIndex: "annotatedValues",
			key: "annotatedValues",
			width: 2,
			render: (annotatedValues, record) => {
				const attrName = record.attribute.attributeName;
				let className = "value";
				if (finalValues[attrName] && finalValues[attrName].field === "finalAttribute") {
					className = className + " verified";
				} else if (finalValues[attrName] && finalValues[attrName].field === "annotation") {
					className = className + " active-value";
				}
				const value = annotatedValues || "-";
				const onClick = () => handleSelectCell(attrName, value, "annotation");
				return <DefaultCell className={className} value={value} onClick={onClick} />;
			},
		},
		{
			title: "Rule Based Value",
			dataIndex: "regexExtractionValues",
			key: "regexExtractionValues",
			width: 2,
			render: (regexExtractionValues, record) => {
				const attrName = record.attribute.attributeName;
				let className = "value";
				if (finalValues[attrName] && finalValues[attrName].field === "finalAttribute") {
					className = className + " verified";
				} else if (finalValues[attrName] && finalValues[attrName].field === "regexExtraction") {
					className = className + " active-value";
				}
				const value = regexExtractionValues || "-";
				const onClick = () => handleSelectCell(attrName, value, "regexExtraction");
				return <DefaultCell className={className} value={value} onClick={onClick} />;
			},
		},
		{
			title: "LLM Based Value",
			dataIndex: "llmExtractionValues",
			key: "llmExtractionValues",
			width: 2,
			render: (llmExtractionValues, record) => {
				const attrName = record.attribute.attributeName;
				let className = "value";
				if (finalValues[attrName] && finalValues[attrName].field === "finalAttribute") {
					className = className + " verified";
				} else if (finalValues[attrName] && finalValues[attrName].field === "llmExtraction") {
					className = className + " active-value";
				}
				const value = llmExtractionValues || "-";
				const onClick = () => handleSelectCell(attrName, value, "llmExtraction");
				return <DefaultCell className={className} value={value} onClick={onClick} />;
			},
		},
		{
			title: "Standardized Value",
			dataIndex: "standardValues",
			key: "standardValues",
			width: 2,
			render: (standardValues, record) => {
				const attrName = record.attribute.attributeName;
				let className = "value";
				if (finalValues[attrName] && finalValues[attrName].field === "finalAttribute") {
					className = className + " verified";
				}
				const value = standardValues || "-";
				return <DefaultCell className={className} value={value} />;
			},
		},
		{
			title: "Final Value",
			dataIndex: "finalValue",
			key: "finalValue",
			width: 2,
			render: (finalValue, record) => {
				const attrName = record.attribute.attributeName;
				let className = "";
				const finalAttributeCheck = finalValues[attrName] && finalValues[attrName].field === "finalAttribute";
				if (finalAttributeCheck) {
					className = className + "verified";
				}
				const value = finalValue || "-";
				const onClick = () => showAttribute(attrName);
				return (
					<FinalCell
						className={className}
						value={value}
						attrName={attrName}
						onClick={onClick}
						saveFinalAttribute={saveFinalAttribute}
						refreshFinalValue={refreshFinalValue}
						annotatedValue={
							record.annotatedValues && record.annotatedValues !== "-" ? record.annotatedValues : ""
						}
						regexValue={
							record.regexExtractionValues && record.regexExtractionValues !== "-"
								? record.regexExtractionValues
								: ""
						}
						llmExtractedValue={
							record.llmExtractionValues && record.llmExtractionValues !== "-"
								? record.llmExtractionValues
								: ""
						}
						finalAttributeCheck={finalAttributeCheck}
					/>
				);
			},
		},
	];

	return (
		<Table
			className="qa-table primary-table"
			columns={columns}
			dataSource={dataSource}
			pagination={false}
			style={{marginBottom: "10px", marginTop: "10px", borderBottom: "solid 1px rgb(0, 0, 0, 0.15)"}}
			scroll={{y: 480}}
			// tableLayout="fixed"
		/>
	);
}
